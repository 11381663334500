<template>

  <!-- BUTTONS -->

  <h2>Btns</h2>
  <p class="styleguide-p-sourcecode">
    <em>Source code: <code>scss/base/_buttons.scss</code></em>
  </p>

  <div class="grid styleguide-buttons">

    <div class="col-tmp">
      <h3 class="styleguide-h3">Colors</h3>
      <Btn text=".btn"/>
      <Btn text=".btn-default" color="default"/>
      <Btn text=".btn-black" color="black"/>
      <Btn text=".btn-gray" color="gray"/>
      <Btn text=".btn-white" color="white"/>
      <Btn text=".btn-primary" color="primary"/>
      <Btn text=".btn-secondary" color="secondary"/>
      <Btn text=".btn-accent" color="accent"/>
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Rounded</h3>
      <Btn text=".btn-round" round/>
      <Btn text=".btn-round" color="default" round/>
      <Btn text=".btn-round" color="black" round/>
      <Btn text=".btn-round" color="gray" round/>
      <Btn text=".btn-round" color="white" round/>
      <Btn text=".btn-round" color="primary" round/>
      <Btn text=".btn-round" color="secondary" round/>
      <Btn text=".btn-round" color="accent" round/>
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Hollow</h3>
      <div style="background: black; padding: 0.75rem; display: inline-block;">
        <Btn text=".btn-hollow" hollow/>
      </div>
      <Btn text=".btn-hollow" color="default" hollow/>
      <Btn text=".btn-hollow" color="black" hollow/>
      <Btn text=".btn-hollow" color="gray" hollow/>
      <div style="background: black; padding: 0.75rem; display: inline-block;">
        <Btn text=".btn-hollow" color="white" hollow/>
      </div>
      <Btn text=".btn-hollow" color="primary" hollow/>
      <Btn text=".btn-hollow" color="secondary" hollow/>
      <Btn text=".btn-hollow" color="accent" hollow/>
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Rounded hollow</h3>
      <div style="background: black; padding: 0.75rem; display: inline-block;">
        <Btn text=".btn-hollow" round hollow/>
      </div>
      <Btn text=".btn-hollow" color="default" round hollow/>
      <Btn text=".btn-hollow" color="black" round hollow/>
      <Btn text=".btn-hollow" color="gray" round hollow/>
      <div style="background: black; padding: 0.75rem; display: inline-block;">
        <Btn text=".btn-hollow" color="white" round hollow/>
      </div>
      <Btn text=".btn-hollow" color="primary" round hollow/>
      <Btn text=".btn-hollow" color="secondary" round hollow/>
      <Btn text=".btn-hollow" color="accent" round hollow/>
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Fat rounded hollow</h3>
      <div style="background: black; padding: 0.75rem; display: inline-block;">
        <Btn text=".btn-hollow" round hollow fat/>
      </div>
      <Btn text=".btn-hollow" color="default" round hollow fat/>
      <Btn text=".btn-hollow" color="black" round hollow fat/>
      <Btn text=".btn-hollow" color="gray" round hollow fat/>
      <div style="background: black; padding: 0.75rem; display: inline-block;">
        <Btn text=".btn-hollow" color="white" round hollow fat/>
      </div>
      <Btn text=".btn-hollow" color="primary" round hollow fat/>
      <Btn text=".btn-hollow" color="secondary" round hollow fat/>
      <Btn text=".btn-hollow" color="accent" round hollow fat/>
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Sizes</h3>
      <Btn text=".btn-xs" color="primary" size="xs"/>
      <Btn text=".btn-sm" color="primary" size="sm"/>
      <Btn text=".btn" color="primary"/>
      <Btn text=".btn-lg" color="primary" size="lg"/>
      <Btn text=".btn-xl" color="primary" size="xl"/>
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Layout</h3>
      <Btn text=".btn-block" color="primary" block/>
      <Btn
        text=".btn-wrap very very very very very very long button multi-line content"
        color="primary"
        wrap
      />
      <Btn
        text=".btn-wrap rounded very very very very very long button multi-line content"
        color="black"
        wrap
        round
      />
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Disabled</h3>
      <Btn text=".btn-disabled" disabled/>
      <Btn text=".btn-disabled" color="default" disabled/>
      <Btn text=".btn-disabled" color="black" disabled/>
      <Btn text=".btn-disabled" color="gray" disabled/>
      <Btn text=".btn-disabled" color="white" disabled/>
      <Btn text=".btn-disabled" color="primary" disabled/>
      <Btn text=".btn-disabled" color="secondary" disabled/>
      <Btn text=".btn-disabled" color="accent" disabled/>
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Background grow</h3>
      <Btn text=".btn-bg-grow" grow icon="calendar"/>
      <Btn grow icon="pie-chart"/>
      <Btn grow icon="science" round/>
      <br>
      <Btn color="default" text=".btn-bg-grow" grow icon="calendar"/>
      <Btn color="default" grow icon="pie-chart"/>
      <Btn color="default" grow icon="science" round/>
      <br>
      <Btn color="black" text=".btn-bg-grow" grow icon="calendar"/>
      <Btn color="black" grow icon="pie-chart"/>
      <Btn color="black" grow icon="science" round/>
      <br>
      <Btn color="gray" text=".btn-bg-grow" grow icon="calendar"/>
      <Btn color="gray" grow icon="pie-chart"/>
      <Btn color="gray" grow icon="science" round/>
      <br>
      <Btn color="white" text=".btn-bg-grow" grow icon="calendar"/>
      <Btn color="white" grow icon="pie-chart"/>
      <Btn color="white" grow icon="science" round/>
      <br>
      <Btn color="primary" text=".btn-bg-grow" grow icon="calendar"/>
      <Btn color="primary" grow icon="pie-chart"/>
      <Btn color="primary" grow icon="science" round/>
      <br>
      <Btn color="secondary" text=".btn-bg-grow" grow icon="calendar"/>
      <Btn color="secondary" grow icon="pie-chart"/>
      <Btn color="secondary" grow icon="science" round/>
      <br>
      <Btn color="accent" text=".btn-bg-grow" grow icon="calendar"/>
      <Btn color="accent" grow icon="pie-chart"/>
      <Btn color="accent" grow icon="science" round/>
    </div>

    <div class="col-tmp">
      <h3 class="styleguide-h3">Icons</h3>
      <h4>Icon before text (default)</h4>
      <Btn text="XS" color="primary" size="xs" icon="calendar"/>
      <Btn text="SM" color="primary" size="sm" icon="pie-chart"/>
      <Btn text="BTN" color="primary" icon="science"/>
      <Btn text="LG" color="primary" size="lg" icon="user"/>
      <Btn text="XL" color="primary" size="xl" icon="times-circle"/>

      <h4>Icon after text</h4>
      <Btn text=".btn-xs" color="secondary" size="xs" icon="calendar" iconAfter/>
      <Btn text=".btn-sm" color="secondary" size="sm" icon="pie-chart" iconAfter/>
      <Btn text=".btn" color="secondary" icon="science" iconAfter/>
      <Btn text=".btn-lg" color="secondary" size="lg" icon="user" iconAfter/>
      <Btn text=".btn-xl" color="secondary" size="xl" icon="times-circle" iconAfter/>

      <h4>Only icon</h4>
      <Btn color="accent" size="xs" icon="calendar"/>
      <Btn color="accent" size="sm" icon="pie-chart"/>
      <Btn color="accent" icon="science"/>
      <Btn color="accent" size="lg" icon="user"/>
      <Btn color="accent" size="xl" icon="times-circle"/>

      <h4>Round, hollow, wrap, block&hellip;</h4>
      <Btn color="black" size="xs" icon="calendar" round/>
      <Btn color="black" size="sm" icon="pie-chart" hollow/>
      <Btn color="black" icon="science" round hollow/>
      <Btn color="black" size="lg" icon="user" round/>
      <Btn color="black" size="xl" icon="times-circle" hollow/>

      <Btn text=".btn-block" color="primary" block icon="calendar"/>
      <Btn
        text=".btn-wrap very very very very very very long button multi-line content"
        color="primary"
        wrap
        icon="pie-chart"
        iconBefore
      />
      <Btn
        text=".btn-wrap rounded very very very very very long button multi-line content"
        color="black"
        wrap
        round
        icon="science"
      />
    </div>

  </div>

</template>

<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'StyleguideBtnsView',

  components: {
    Btn,
  },
}
</script>

<style lang="scss" scoped>
/* STYLEGUIDE - BUTTONS*/

.styleguide-buttons {
  .btn {
    margin-bottom: $gutter-quarter;
    margin-right: $gutter-quarter;
  }
}
</style>
